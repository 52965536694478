function selectCard(n, amount) {
  var card = document.getElementsByClassName("card");
  var btn = document.getElementsByClassName("button-equi")[0];
  if (document.documentElement.clientWidth > 992)
    var input = document.getElementsByTagName("input")[1];
  else var input = document.getElementsByTagName("input")[0];
  if (document.documentElement.clientWidth > 992) n = n + 4;

  for (var i = 0; i < 8; i++) {
    card[i].classList.remove("active");
  }
  card[n].classList.add("active");
  if (amount == 0) {
    var output = parseInt(input.value);
    if (window.location.search === "") {
      btn.href =
        "https://don.lejourduseigneur.com/b?cid=37&lang=fr_FR&utm_campaign=FA24&utm_source=LP&utm_medium=organic&reserved_code_media=LPAVT24&amount=" +
        output * 100;
    } else {
      btn.href =
        "https://don.lejourduseigneur.com/avent2024/~mon-don?utm_campaign=FA24&utm_source=LP&utm_medium=organic&reserved_code_media=LPAVT24" +
        window.location.search +
        "&amount=" +
        output * 100;
    }
    output = output - (output * 66) / 100;
    if (!isNaN(output)) {
      output = Math.round(output);
      document.getElementById("sum").innerHTML = output + "€";
      document.getElementById("sum1").innerHTML = output + "€";
    } else {
      document.getElementById("sum").innerHTML = "0€";
      document.getElementById("sum1").innerHTML = "0€";
    }
  } else {
    if (window.location.search === "") {
      console.log(amount)
      btn.href =
        "https://don.lejourduseigneur.com/b?cid=37&lang=fr_FR&utm_campaign=FA24&utm_source=LP&utm_medium=organic&reserved_code_media=LPAVT24&amount=" +
        amount * 100;
    } else {
      btn.href =
        "https://don.lejourduseigneur.com/avent2024/~mon-don?utm_campaign=FA24&utm_source=LP&utm_medium=organic&reserved_code_media=LPAVT24" +
        window.location.search +
        "&amount=" +
        amount * 100;
    }
  }
}

var input = document.getElementsByTagName("input")[0];
var btn = document.getElementsByClassName("button-equi")[0];
