window.addEventListener("load", function (event) {
  var date1 = new Date("December 1, 2021 23:59:59 GMT+01:00");
  var date2 = new Date("December 12, 2021 23:59:59 GMT+01:00");
  var date3 = new Date("December 15, 2021 23:59:59 GMT+01:00");
  var date4 = new Date("December 22, 2021 23:59:59 GMT+01:00");
  var date5 = new Date("December 25, 2021 23:59:59 GMT+01:00");
  const today = Date.now();

  if (today < date1.getTime()) selectDate(0);
  else if (today < date2.getTime() && today > date1.getTime()) selectDate(1);
  else if (today < date3.getTime() && today > date2.getTime()) selectDate(2);
  else if (today < date4.getTime() && today > date3.getTime()) selectDate(3);
  else if (today < date5.getTime() && today > date4.getTime()) selectDate(4);
});
