function selectDate(n) {
  var item = document.getElementsByClassName("date-list-item");
  var page = document.getElementsByClassName("page");

  for (var i = 0; i < 6; i++) {
    item[i].classList.remove("active");
    page[i].classList.remove("active");
  }
  item[n].classList.add("active");
  page[n].classList.add("active");
  changeDate(n);
}

function changeDate(n) {
  var date = [
    {
      title2:
        "Messe du 1<sup>er</sup> dimanche de l’Avent en direct de Tangissart",
      title1: "",
      text2: `En direct de l’église Notre-Dame de Tangissart (diocèse de Malines-Bruxelles, Belgique), nous célébrerons la messe du premier dimanche de l’Avent. Cette messe sera présidée par l’abbé Emmanuel Leurquin et prêchée par l’abbé Pierre Hannosset.`,
      credit: "© Pascal Lemaître. All rights reserved 2024 / Bridgeman Images",
    },
    {
      title2: "Réouverture solennelle<br/> de Notre-Dame",
      title1: "",
      text2: `En direct de la cathédrale Notre-Dame de Paris, restaurée cinq ans après l’incendie, nous vous invitons à vivre la messe de réouverture en ce deuxième dimanche de l’Avent. Cette cérémonie, marquée par la consécration de l’autel, sera présidée par Monseigneur Laurent Ulrich, archevêque de Paris, en présence de nombreux chefs d’État et dignitaires religieux.`,
      credit: "© shutterstock",
    },
    {
      title2: "Messe du dimanche de Gaudete<br/>en direct de Saint-Didier",
      title1: "",
      text2: `En direct de l’église Saint-Didier (Vaucluse), nous célébrerons la messe du troisième dimanche de l’Avent, ou dimanche de Gaudete. Ce lieu est connu pour l’atelier Quoirin, qui a restauré le grand orgue de Notre-Dame de Paris. La messe sera présidée par le père Robert Culat, accompagné du père Jean-Marie Petitclerc, salésien de Don Bosco.`,
      credit: "© shutterstock",
    },
    {
      title2: "Hommage aux charpentiers<br/> de Notre-Dame",
      title1: "",
      text2: `En direct de l’église Saint-Laurent à Saint-Laurent-de-la-Plaine (Maine-et-Loire), nous célébrons la messe du quatrième dimanche de l’Avent. Les Ateliers Perrault, ayant participé à la reconstruction de la charpente de Notre-Dame, seront mis à l’honneur, avec Saint Joseph, patron des charpentiers. La messe sera présidée par le père Pierre-Étienne Grislin et prêchée par le frère Camille de Belloy, dominicain.`,
      credit: "© GettyImages – Sedmak",
    },
    {
      title2: "Ouverture de la Porte Sainte",
      title1: "",

      text2: `La solennité de la Nativité du Seigneur sera célébrée à minuit avec la messe de la nuit de Noël, présidée par le pape François en direct depuis la basilique Saint-Pierre à Rome. Cette messe marquera également l’ouverture de la Porte Sainte en vue du Jubilé 2025.`,
      credit: "© shutterstock",
    },
    {
      title2: "Célébration de Noël<br/> à Notre-Dame",
      title1: "",

      text2: `Le jour de Noël, la Nativité du Seigneur sera célébrée en direct de Notre-Dame à 11h, avec une messe émouvante. À midi, le pape François prononcera la bénédiction <span class="font-italic">Urbi et orbi</span> depuis le Vatican. À 12h20, le documentaire <span class="font-italic">Vitrail, et la lumière fut</span> de Lucile Bellanger explorera les vitraux des églises et cathédrales. La messe sera présidée par Monseigneur Laurent Ulrich, archevêque de Paris.`,
      credit: "© shutterstock",
    },
  ];

  var bg = document.getElementsByClassName("date")[0];
  var title2 = document.getElementsByClassName("date-content-title2")[0];
  var title1 = document.getElementsByClassName("date-content-title1")[0];
  var title1a = document.getElementsByClassName("date-content-title1")[1];
  var text2 = document.getElementsByClassName("date-content-text2")[0];
  var credit = document.getElementsByClassName("date-credit")[0];
  var i = n + 1;

  if (document.documentElement.clientWidth < 500)
    bg.style.backgroundImage = "url('dist/asset/img/date-bg" + i + "-mb.jpg')";
  else bg.style.backgroundImage = "url('dist/asset/img/date-bg" + i + ".jpg')";
  title2.innerHTML = date[n]["title2"];
  title1.innerHTML = date[n]["title1"];
  title1a.innerHTML = date[n]["title1"];
  text2.innerHTML = date[n]["text2"];
  credit.innerHTML = date[n]["credit"];
}

function resize() {
  var bg = document.getElementById("date");
  var page = document.getElementsByClassName("page");

  var n;

  for (var i = 0; i < 6; i++) if (page[i].classList.contains("active")) n = i;
  if (document.documentElement.clientWidth < 500) {
    bg.setAttribute(
      "background-image",
      "url('dist/asset/img/date-bg" + n + "-mb.jpg')"
    );
  } else {
    bg.setAttribute(
      "background-image",
      "url('dist/asset/img/date-bg" + n + ".jpg')"
    );
  }

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  var navbar = document.getElementById("navigation");
  var header = document.getElementById("header");

  header.style.marginTop = navbar.offsetHeight + "px";
  header.style.height = vh - navbar.offsetHeight + "px";
}

var currentDate = new Date().toJSON().slice(0, 10);
var check = new Date(currentDate);
var start = new Date("2000/01/01");
var d1 = new Date("2024/12/01");
var d2 = new Date("2024/12/08");
var d3 = new Date("2024/12/15");
var d4 = new Date("2024/12/22");
var d5 = new Date("2024/12/24");
var d6 = new Date("2024/12/25");

if (check >= start && check <= d1) {
  selectDate(0);
}
if (check > d1 && check <= d2) {
  selectDate(1);
}
if (check > d2 && check <= d3) {
  selectDate(2);
}
if (check > d3 && check <= d4) {
  selectDate(3);
}
if (check > d4 && check <= d5) {
  selectDate(4);
}

if (check > d5 && check <= d6) {
  selectDate(5);
}

if (check >= d6) {
  selectDate(5);
}

document.getElementsByClassName("player")[0].style.display = "none";

function togglePlayer() {
  let player = document.getElementsByClassName("player")[0];
  let btn = document.getElementsByClassName("play")[0];
  let iframe = document.getElementById("iframe");
  if (!player || !btn || !iframe) return;
  if (player.style.display === "none") {
    player.style.display = "flex";
    btn.style.display = "none";
    iframe.src =
      "https://www.youtube-nocookie.com/embed/pVlfnM0k5m4?si=4arQ5u5gpb78rj7o";
  } else {
    btn.style.display = "inline-block";
    player.style.display = "none";
    iframe.src = "";
  }
}

function detectAnchor() {
  const dates = ["#0112", "#0812", "#1512", "#22.12", "#24.12", "#25.12"];
  const currentAnchor = window.location.hash;

  const index = dates.indexOf(currentAnchor);

  if (index !== -1) {
    selectDate(index);
    const element = document.getElementById("date");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
}

window.addEventListener("hashchange", detectAnchor);

detectAnchor();
